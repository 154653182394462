var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    {
      staticClass:
        "flex justify-between items-center w-full py-2 pr-2 pl-4 mb-1 font-light"
    },
    [
      _c(
        "span",
        {
          staticClass:
            "flex flex-wrap justify-start items-center w-full lg:text-base"
        },
        [
          _c(
            "svg",
            {
              staticClass: "w-5 h-5 mr-3 mb-0",
              attrs: { width: "20", height: "20" }
            },
            [_c("use", { attrs: { "xlink:href": "#" + _vm.icon } })]
          ),
          _vm._v(" "),
          _c("span", { staticClass: "leading-none" }, [
            _vm._v(_vm._s(_vm.label))
          ])
        ]
      ),
      _vm._v(" "),
      _vm.hasSubnav
        ? _c(
            "svg",
            { staticClass: "w-3 h-3", attrs: { width: "12", height: "12" } },
            [_c("use", { attrs: { "xlink:href": "#gt" } })]
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }