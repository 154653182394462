var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "flex justify-between w-full" }, [
    _c(
      "button",
      {
        staticClass: "sidebar_nav-link w-full mb-0",
        class: { "bg-gray-800": _vm.open },
        on: {
          click: function($event) {
            $event.stopPropagation()
            return _vm.toggleMenu($event)
          }
        }
      },
      [
        _c("menu-link", {
          attrs: { label: _vm.label, icon: _vm.icon, "has-subnav": true }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        ref: "submenu",
        staticClass: "sidebar_nav-submenu-wrap",
        class: { "sidebar_nav-submenu-wrap-open": _vm.open }
      },
      [
        _c("div", { staticClass: "sidebar_nav-submenu_header" }, [
          _vm._v(_vm._s(_vm.label))
        ]),
        _vm._v(" "),
        _c(
          "ul",
          { staticClass: "h-full pt-2 list-none pl-0 overflow-y-scroll" },
          _vm._l(_vm.items, function(route, anchor) {
            return _c(
              "li",
              { key: route, staticClass: "mb-1" },
              [
                _c(
                  "router-link",
                  {
                    staticClass:
                      "sidebar_nav-link block py-2 px-4 hover:bg-blue-800",
                    attrs: { to: { name: route } },
                    nativeOn: {
                      click: function($event) {
                        return _vm.subnavItemClicked($event)
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(anchor) +
                        "\n                "
                    )
                  ]
                )
              ],
              1
            )
          }),
          0
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }