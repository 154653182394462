var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "field-wrap" },
    [
      _c("label", {
        attrs: { for: _vm.id },
        domProps: { innerHTML: _vm._s(_vm.label) }
      }),
      _vm._v(" "),
      _c("field-errors", { attrs: { errors: _vm.serverValidationErrors } }),
      _vm._v(" "),
      _vm._t("errors"),
      _vm._v(" "),
      _vm.hackedPassword
        ? _c("field-error", [
            _vm._v(
              "\n        It appears that this password was part of a data breach\n        and may not be accepted. Consider using a different password.\n    "
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "relative" }, [
        _c("input", {
          staticClass: "pr-10",
          attrs: {
            id: _vm.id,
            name: _vm.field,
            type: _vm.fieldType,
            required: _vm.required,
            autocomplete: _vm.autocomplete,
            autocapitalize: "off",
            autocorrect: "off",
            spellcheck: "false"
          },
          domProps: { value: _vm.value },
          on: {
            input: function($event) {
              return _vm.$emit("input", $event.target.value)
            }
          }
        }),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass:
              "absolute button-link block top-0 right-0 w-6 h-6 mr-2 text-gray-600 hover:text-gray-800",
            staticStyle: { "margin-top": "0.3rem" },
            attrs: { type: "button" },
            on: {
              click: function($event) {
                $event.preventDefault()
                _vm.visible = !_vm.visible
              }
            }
          },
          [
            _c(
              "svg",
              {
                staticClass: "w-6 h-6 fill-current",
                attrs: { width: "24", height: "24" }
              },
              [_c("use", { attrs: { "xlink:href": _vm.icon } })]
            )
          ]
        )
      ]),
      _vm._v(" "),
      _vm.showHelp
        ? _c("div", { staticClass: "field-help" }, [
            _vm._v(
              "\n        Must be at least " +
                _vm._s(_vm.minLength) +
                " characters long.\n    "
            )
          ])
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }