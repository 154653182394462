var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.ready
    ? _c(
        "div",
        [
          _c("svg-icons", { attrs: { src: _vm.iconsPath } }),
          _vm._v(" "),
          _c(
            "nav",
            { staticClass: "sidebar_nav-wrap" },
            [
              _c(
                "router-link",
                {
                  staticClass:
                    "flex items-center w-40 lg:w-64 border-b border-gray-600",
                  staticStyle: { height: "3.75rem", padding: "0.65rem 0" },
                  attrs: { to: _vm.logoLinkRoute }
                },
                [
                  _c("img", {
                    staticClass: "h-10 ml-4",
                    attrs: {
                      src: "/images/logo.svg",
                      height: "70",
                      alt: "Invoice Mailer"
                    }
                  })
                ]
              ),
              _vm._v(" "),
              _c(
                "ul",
                { staticClass: "sidebar_nav-nav" },
                [
                  _vm.loggedIn
                    ? [
                        _vm.hasRole("ROLE_ADMIN")
                          ? _c(
                              "li",
                              { staticClass: "mb-2 lg:mb-0 hover:bg-gray-800" },
                              [
                                _c(
                                  "router-link",
                                  {
                                    staticClass: "sidebar_nav-link",
                                    attrs: { to: { name: "invoices" } }
                                  },
                                  [
                                    _c("menu-link", {
                                      attrs: { label: "Invoices", icon: "gear" }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.hasRole("ROLE_ADMIN")
                          ? _c(
                              "li",
                              { staticClass: "hover:bg-gray-800" },
                              [
                                _c("menu-subnav", {
                                  attrs: {
                                    items: _vm.adminMenuItems,
                                    label: "Admin",
                                    icon: "gear"
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e()
                      ]
                    : _vm._e()
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "absolute bottom-0 w-40 lg:w-64 pt-2 text-gray-500 font-thin"
                },
                [
                  _c(
                    "div",
                    { staticClass: "flex items-end justify-between px-4" },
                    [
                      _vm.loggedIn
                        ? _c(
                            "div",
                            {
                              staticClass: "w-3/5 lg:w-2/3 mb-2 text-sm",
                              staticStyle: { "overflow-wrap": "break-word" }
                            },
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass:
                                    "text-inherit hover:no-underline hover:text-white",
                                  attrs: { to: { name: "user-profile-edit" } }
                                },
                                [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(_vm.profileLinkText) +
                                      "\n                    "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.loggedIn
                        ? _c("div", { staticClass: "pb-2 pl-4 text-xs" }, [
                            _c(
                              "a",
                              {
                                staticClass:
                                  "text-inherit hover:no-underline hover:text-white",
                                attrs: { href: "/logout" }
                              },
                              [_vm._v("Logout")]
                            )
                          ])
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "py-2 pl-4 text-xs text-gray-600 border-t border-gray-600"
                    },
                    [
                      _vm._v(
                        "\n                ©" +
                          _vm._s(_vm.copyrightYear) +
                          " XM Media Inc.\n            "
                      )
                    ]
                  )
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "header",
            { staticClass: "header-wrap-small" },
            [
              _vm.loggedIn ? _c("menu-small") : _vm._e(),
              _vm._v(" "),
              _c(
                "router-link",
                { staticClass: "ml-auto", attrs: { to: _vm.logoLinkRoute } },
                [
                  _c("img", {
                    staticClass: "h-10 -mt-4 mr-4",
                    attrs: {
                      src: "/images/logo.svg",
                      height: "70",
                      alt: "Invoice Mailer"
                    }
                  })
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "content-wrap js-content-wrap" }, [
            _c(
              "header",
              { staticClass: "header-wrap" },
              [
                _c(
                  "h1",
                  { staticClass: "header-page_title font-thin" },
                  [
                    _c("portal-target", {
                      attrs: { name: "header-page-title" }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c("portal-target", {
                  staticClass: "header-actions",
                  attrs: { name: "header-actions" }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c("main", { staticClass: "p-4" }, [_c("router-view")], 1)
          ]),
          _vm._v(" "),
          _c("portal-target", { attrs: { name: "modal", multiple: "" } })
        ],
        1
      )
    : _c("loading-spinner", { staticClass: "mt-8" })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }