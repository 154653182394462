var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.showButton
        ? _c(
            "button",
            { staticClass: "button", attrs: { type: "submit" } },
            [_vm._t("default", [_vm._v("Save")])],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm._t("cancel", [
        _vm.cancelTo
          ? _c(
              "router-link",
              { staticClass: "form-action", attrs: { to: _vm.cancelTo } },
              [_vm._v("Cancel")]
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      _vm.status === "saving"
        ? _c(
            "span",
            { staticClass: "ml-4 text-sm italic" },
            [
              _c("span", {
                staticClass: "loading",
                attrs: { "aria-hidden": "true" }
              }),
              _vm._v(" "),
              _vm._t("saving", [_vm._v("Saving...")])
            ],
            2
          )
        : _vm.status === "saved"
        ? _c(
            "span",
            { staticClass: "ml-4 text-sm italic" },
            [_vm._t("saved", [_vm._v("Saved")])],
            2
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }