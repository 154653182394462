var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass: "button-link text-white -mt-4 ml-4",
      on: {
        click: function($event) {
          $event.stopPropagation()
          return _vm.toggleMenu($event)
        }
      }
    },
    [_vm._v("Menu")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }